html,
body,
.animated-routes-container,
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  font-family: "Poppins";
  font-weight: 400;
}

.animated-container {
  height: 100%;
  width: 100%;
}

/*Chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Breakpoints */
@media screen and (max-width: 1600px) {
  html,
  body,
  #root {
    font-size: 14px;
  }
}

.App {
  width: 100%;
  height: calc(100% - 60px);
}

/* Scrollbars */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #07c8c0;
  border: 0px none #07c8c0;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #07c8c0;
}
::-webkit-scrollbar-thumb:active {
  background: #07c8c0;
}
::-webkit-scrollbar-track {
  background: #f8f9fd;
  border: 0px none #07c8c0;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #fff;
}
::-webkit-scrollbar-track:active {
  background: #fff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: medium;
  font-weight: 700;
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: medium;
  font-weight: 800;
  src: url("../assets/fonts/poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: medium;
  font-weight: 900;
  src: url("../assets/fonts/poppins/Poppins-Black.ttf");
}

.confirm-modal .swal2-confirm {
  background: #dc3545;
}
